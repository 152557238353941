/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Banner, Card, Stack, Button, Headline, BodyText} from '@servicetitan/design-system';
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code",
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    hr: "hr",
    ul: "ul",
    li: "li",
    h2: "h2",
    h3: "h3",
    strong: "strong"
  }, _provideComponents(), props.components);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 300\n---\n<Dialog\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\ttitle=\"Save for later?\"\n\tportal={false}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Save\"\n\tonSecondaryActionClick={() => {}}\n\tsecondaryActionName=\"Cancel\"\n>\n\tYou can always set up this campaign at a later time.\n</Dialog>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={false}>\n\t{([open, setOpen]) => (\n\t\t<div>\n\t\t\t<Button onClick={() => setOpen(true)}>Live Demo</Button>\n\t\t\t<Dialog\n\t\t\t\topen={open}\n\t\t\t\tonClose={() => setOpen(false)}\n\t\t\t\ttitle=\"Closable Dialog\"\n\t\t\t\tonPrimaryActionClick={() => setOpen(false)}\n\t\t\t\tprimaryActionName=\"Save\"\n\t\t\t\tonSecondaryActionClick={() => setOpen(false)}\n\t\t\t\tsecondaryActionName=\"Cancel\"\n\t\t\t>\n\t\t\t\tBoth buttons in this demo will close the dialog.\n\t\t\t</Dialog>\n\t\t</div>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "negative--destructive",
    style: {
      position: "relative"
    }
  }, "Negative / Destructive", React.createElement(_components.a, {
    href: "#negative--destructive",
    "aria-label": "negative  destructive permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The dialog's primary action can signify a destructive action. Negative dialogs contain two actions."), "\n", React.createElement(Banner, {
    status: "success",
    title: "Do this"
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 300\n---\n<Dialog\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\ttitle=\"Delete email\"\n\tportal={false}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Delete\"\n\tonSecondaryActionClick={() => {}}\n\tsecondaryActionName=\"Cancel\"\n\tnegative\n>\n\tAre you sure you want to delete this email and it scheduling interval?\n</Dialog>\n")), "\n", React.createElement(Banner, {
    status: "critical",
    title: "Don't do this"
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 300\n---\n<Dialog\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\ttitle=\"Delete email\"\n\tportal={false}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Delete\"\n\tnegative\n>\n\tAre you sure you want to delete this email and it scheduling interval?\n</Dialog>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "actions",
    style: {
      position: "relative"
    }
  }, "Actions", React.createElement(_components.a, {
    href: "#actions",
    "aria-label": "actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Dialogs require at least one action, with no more than two."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When only one action is provided, it is an acknowledgement action."), "\n", React.createElement(_components.li, null, "When two actions are provided, the primary action is a confirming action, and the secondary a dismissing action."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "non-closable-dialog",
    style: {
      position: "relative"
    }
  }, "Non-closable Dialog", React.createElement(_components.a, {
    href: "#non-closable-dialog",
    "aria-label": "non closable dialog permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Dialog should be closable in most cases, by clicking on the background around the dialog. For other cases, non-closable Dialog can be implemented removing ", React.createElement(_components.code, null, "onClose"), " prop. This creates a mandatory action in a workflow."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={false}>\n\t{([open, setOpen]) => (\n\t\t<div>\n\t\t\t<Button onClick={() => setOpen(true)}>Demo Non-closable Dialog</Button>\n\t\t\t<Dialog\n\t\t\t\topen={open}\n\t\t\t\ttitle=\"Closable Dialog\"\n\t\t\t\tonPrimaryActionClick={() => setOpen(false)}\n\t\t\t\tprimaryActionName=\"I agree\"\n\t\t\t>\n\t\t\t\tThis is something of great importance.\n\t\t\t</Dialog>\n\t\t</div>\n\t)}\n</State>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "dialog-examples",
    style: {
      position: "relative"
    }
  }, "Dialog Examples", React.createElement(_components.a, {
    href: "#dialog-examples",
    "aria-label": "dialog examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "examples-with-two-actions",
    style: {
      position: "relative"
    }
  }, "Examples with Two Actions", React.createElement(_components.a, {
    href: "#examples-with-two-actions",
    "aria-label": "examples with two actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 300\n---\n<Dialog\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\ttitle=\"Call Jane Doe?\"\n\tportal={false}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Call\"\n\tonSecondaryActionClick={() => {}}\n\tsecondaryActionName=\"Cancel\"\n>\nDo you want to call Jane Doe at (123){'\\u00A0'}456–7890?\n</Dialog>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 300\n---\n<Dialog\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\ttitle=\"Send to Closed?\"\n\tportal={false}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Send\"\n\tonSecondaryActionClick={() => {}}\n\tsecondaryActionName=\"Cancel\"\n>\nAre you sure you want to send this to closed? You can’t reply to closed{'\\u00A0'}messages.\n</Dialog>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 350\n---\n<Dialog\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\ttitle=\"Confirmation\"\n\tportal={false}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Continue\"\n\tonSecondaryActionClick={() => {}}\n\tsecondaryActionName=\"Cancel\"\n>\n\t<p className=\"m-t-0\">You are about to save changes to an active template. It may take a while for the changes to take effect in Inventory.</p>\n\t<p>Would you like to continue?</p>\n</Dialog>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 350\n---\n<Dialog\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\ttitle=\"Delete Report\"\n\tportal={false}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Delete Report\"\n\tonSecondaryActionClick={() => {}}\n\tsecondaryActionName=\"Cancel\"\n\tnegative\n>\n\t<p className=\"m-t-0\">You have 3 scheduled reports for Accounts Receivable Summary by Invoice Type.</p>\n\t<p>Deleting this report will also delete its scheduled reports.</p>\n</Dialog>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 300\n---\n<Dialog\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\ttitle=\"Deactivation Confirmation\"\n\tportal={false}\n\tonSecondaryActionClick={() => {}}\n\tsecondaryActionName=\"Cancel\"\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Deactivate\"\n\tnegative\n>\n\tOnce deactivated, serial numbers associated with this item will be deleted and cannot be recovered.\n</Dialog>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 300\n---\n<Dialog\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\tportal={false}\n\ttitle=\"Discard drafts from this conversation?\"\n\tonSecondaryActionClick={() => {}}\n\tsecondaryActionName=\"Cancel\"\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Discard\"\n/>\n")), "\n", React.createElement(_components.h2, {
    id: "examples-with-one-acknowledgement-action",
    style: {
      position: "relative"
    }
  }, "Examples with One Acknowledgement Action", React.createElement(_components.a, {
    href: "#examples-with-one-acknowledgement-action",
    "aria-label": "examples with one acknowledgement action permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 300\n---\n<Dialog\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\ttitle=\"Not in Stock\"\n\tportal={false}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Ok\"\n>\n\tThis item is no longer available.\n</Dialog>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 300\n---\n<Dialog\n\topen\n\tfocusTrapOptions={{ disabled: true }}\n\ttitle=\"Set up Later\"\n\tportal={false}\n\tonPrimaryActionClick={() => {}}\n\tprimaryActionName=\"Ok\"\n>\n\tNo worries. You can always set up TitanPhone later in <Link primary>Settings > Phone</Link>.\n</Dialog>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "content-guidelines",
    style: {
      position: "relative"
    }
  }, "Content Guidelines", React.createElement(_components.a, {
    href: "#content-guidelines",
    "aria-label": "content guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "write-titles-as-verbs",
    style: {
      position: "relative"
    }
  }, "Write titles as verbs", React.createElement(_components.a, {
    href: "#write-titles-as-verbs",
    "aria-label": "write titles as verbs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Titles should have a clear verb + noun question or statement."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 4
  }, React.createElement(Headline, null, "Edit customer information"), React.createElement(Headline, null, "Delete message?"), React.createElement(Headline, null, "Discard unsaved changes?"))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 3
  }, React.createElement(Headline, null, "Edit the service agreement for this customer"), React.createElement(Headline, null, "Are you sure you want to remove the invoice?"), React.createElement(Headline, null, "Delete?"))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h3, {
    id: "make-body-content-concise-and-actionable",
    style: {
      position: "relative"
    }
  }, "Make body content concise and actionable", React.createElement(_components.a, {
    href: "#make-body-content-concise-and-actionable",
    "aria-label": "make body content concise and actionable permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Use imperative verbs when telling users what they need to know or do. Don’t use permissive language like \"you can\"."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(BodyText, null, "Notification emails will be sent to this address."), React.createElement(BodyText, null, "This cannot be undone."))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(BodyText, null, "You can edit the email address where emails will be sent."), React.createElement(BodyText, null, "Are you sure you want to delete this job? You can’t reverse this."))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h3, {
    id: "buttons-should-be-clear-predictable-and-action-oriented",
    style: {
      position: "relative"
    }
  }, "Buttons should be clear, predictable, and action-oriented", React.createElement(_components.a, {
    href: "#buttons-should-be-clear-predictable-and-action-oriented",
    "aria-label": "buttons should be clear predictable and action oriented permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Users should be able to predict what will happen when they click a button."), "\n", React.createElement(_components.p, null, "Lead with a strong verb that encourages action. Use a verb/noun pair on actions in most cases. Common actions like Save, Close, or Cancel do not require an accompanying nou"), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Button, {
    size: "small",
    primary: true
  }, "Create Order"), React.createElement(Button, {
    size: "small",
    primary: true
  }, "Print Label"))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Button, {
    size: "small",
    primary: true
  }, "New invoice"), React.createElement(Button, {
    size: "small",
    primary: true
  }, "Schedule"))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "differences-from-a-modal",
    style: {
      position: "relative"
    }
  }, "Differences from a Modal", React.createElement(_components.a, {
    href: "#differences-from-a-modal",
    "aria-label": "differences from a modal permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Dialogs are more restrictive in their options relative to modals."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Can only be X-Small in size."), "\n", React.createElement(_components.li, null, "Cannot have scrolling content."), "\n", React.createElement(_components.li, null, "Cannot have the close icon ", React.createElement(_components.code, null, "×"), " in the top right."), "\n", React.createElement(_components.li, null, "Must have one or two actions."), "\n", React.createElement(_components.li, null, "The footer can only be right aligned, with only solid-filled buttons."), "\n", React.createElement(_components.li, null, "Only the primary action button can be customized: primary solid or negative solid."), "\n", React.createElement(_components.li, null, "Body content is optional."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Dialogs should only be used if they require the user to be interrupted."), "\n", React.createElement(_components.li, null, "For actions, avoid 'Yes' and 'No' responses."), "\n", React.createElement(_components.li, null, "If an action involves cancelling an operation, it should be the secondary action, usually labeled 'Cancel'."), "\n", React.createElement(_components.li, null, "Like modals, dialogs should be used sparingly. Particularly with one action dialogs."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-components",
    style: {
      position: "relative"
    }
  }, "Related Components", React.createElement(_components.a, {
    href: "#related-components",
    "aria-label": "related components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "For more complex interactions that require user interruption, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/modal"
  }, "Modal")), "."), "\n", React.createElement(_components.li, null, "To completely hide the context of the page, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/takeover/"
  }, "Takeover")), " component."), "\n", React.createElement(_components.li, null, "For information that does not require user interruption, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/toast"
  }, "Toast")), " or ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/banner"
  }, "Banner")), " notification."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { Dialog } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
